const AboutMe = () => {
  return (
    <section>
      <h2 className="title-font pink-text h2-tag">About Me</h2>
      <p className="white-text p-tag">
        I'm Ahmed Hamam, and my passion for technology began in high school when
        I discovered coding through Arduino and C++. The thrill of turning code
        into something I could physically interact with drove me to dive deeper
        into hardware, even though I couldn't afford the parts at first. After
        saving up, I built an RC car with obstacle detection and motion sensors,
        an experience that was both rewarding and exciting. Later, I shifted to
        web development, drawn by the same joy of creating. JavaScript felt like
        building with bricks—solid and structured. I’ve since honed my skills in
        HTML, CSS, and React.js, working with teams on real projects. My journey
        in tech is just getting started, and I’m eager to see where it leads.
      </p>
    </section>
  );
};
export default AboutMe;
