import AboutPreview from './AboutPreview';
import HeaderPreview from './HeaderPreview';
import PortfolioPreview from './PortfolioPreview';
import ContactPreview from './ContactPreview';
import { Footer } from '../../components';
import ScrollButton from '../../components/ScrollButton';
import { useEffect } from 'react';
import './Landing.sass';

const Mainpage = () => {
  useEffect(() => {
    document.title = 'Ahmed Hamam | Personal Portfolio';
    window.scrollTo(0, 0);
  });
  return (
    <article className="main-page">
      <HeaderPreview />
      <AboutPreview />
      <PortfolioPreview />
      <ContactPreview />
      <ScrollButton />
      <Footer />
    </article>
  );
};

export default Mainpage;
