const experienceData = [
  {
    id: 1,
    jobtitle: 'Freelance Frontend Developer',
    companyname: 'Self-Employed',
    worktype: 'Full-time',
    duration: 'Jun 2023 – present',
    responsibility: [
      `Work as a freelance frontend developer, collaborating with clients on various projects.`,
      `Collaborated closely with clients to understand their requirements and translate them into functional and visually appealing frontend solutions.`,
      `Conducted market research and competitor analysis, identifying key trends and insights to refine campaign strategies.`,
    ],
  },
  {
    id: 2,
    jobtitle: 'Frontend Developer',
    companyname: 'GlaxyTech',
    worktype: 'Full-time',
    duration: 'Mar 2020 – Feb 20210',
    location: 'Munich, Germany - Remote',
    responsibility: [
      `As a front-end developer, I worked in an agile environment with a wonderful team on innovative and breakthrough projects that affect how we live, communicate, or work together.`,
      `Worked in a B2B e-commerce website specializing in creating responsive user interfaces, adept at translating designs into polished, high-performance code with HTML, CSS, JavaScript, and React.js.`,
      `Worked at this company with technical skills in JavaScript and React.js and soft skills with communication skills using Jira and Slack for task organization.`,
      `Using GitHub and GitLab: allowed us to collaborate to work on the same Git project from different locations around the world.`,
      `Using Figma: allows us real-time collaboration, feedback integration, design system creation, and streamlining the design process between the frontend team (web and mobile) and the backend team to build APIs.`,
    ],
  },
];

const educationData = [
  {
    name: 'Mansoura University',
    status: 'Bachelor of Law',
    year: 'Sep 2019 - Jun 2023',
  },
];

const certificationData = [
  {
    name: 'Responsive Web Design',
    source: 'freeCodeCamp',
    date: 'Issued Jun 2024 · No Expiration Date',
  },
  {
    name: 'Git and GitHub',
    source: 'IBM',
    date: 'Issued May 2024 · No Expiration Date',
  },
];

export { experienceData, educationData, certificationData };
