import React from 'react';

const Stack = () => {
  return (
    <React.Fragment>
      <h3 className="title-font white-text ">
        Here are a few tools & technologies I’ve been working with:
      </h3>
      <ol className="stack-section">
        <li>HTML5</li>
        <li>CSS3</li>
        <li>Bootstrap5</li>
        <li>Tailwind</li>
        <li>SCSS/SASS</li>
        <li>JavaScript ES6+</li>
        <li>React.js</li>
        <li>npm</li>
        <li>RESTful API</li>
        <li>Git</li>
        <li>GitLab</li>
        <li>GitHub</li>
        <li>Trello</li>
        <li>Jira</li>
        <li>Figma</li>
        <li>Visual Studio Code</li>
      </ol>
    </React.Fragment>
  );
};

export default Stack;
