import Linkedin from '../../assets/images/socials/linkedin-pink.svg';
import Github from '../../assets/images/socials/github-pink.svg';
import Facebook from '../../assets/images/socials/facebook-pink.svg';
import Whatsapp from '../../assets/images/socials/whatsapp-pink.svg';
const NavigationSocials = () => {
  return (
    <section className="social-media">
      <a
        href="https://www.linkedin.com/in/ahmed--hamam/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Linkedin} alt="linkedin" />
      </a>
      <a href="https://github.com/AhmedHamamm" target="_blank" rel="noreferrer">
        <img src={Github} alt="github" />
      </a>
      <a
        href="https://www.facebook.com/ahamam2000"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Facebook} alt="facebook" />
      </a>
      <a href="https://wa.me/201097272932" target="_blank" rel="noreferrer">
        <img src={Whatsapp} alt="whatsapp" />
      </a>
    </section>
  );
};

export default NavigationSocials;
