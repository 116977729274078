import Bankist from '../assets/images/portfolio/Bankist.png';
import PigGame from '../assets/images/portfolio/Pig-game.png';
import Countries from '../assets/images/portfolio/countries.png';
import SushiParadise from '../assets/images/portfolio/sushi-paradise.png';
import GuessMyNumber from '../assets/images/portfolio/guess-my-number.png';

const projectsData = [
  {
    id: 'bankist',
    img: Bankist,
    name: 'Bankist',
    stack: ['< Vanilla JS />', '< CSS3 />', '< HTML5 />'],
    src: 'https://www.ahamam.com/bankist/',
    source: 'https://github.com/AhmedHamamm/Bankist-app',
    description:
      'Bankist is a fictional online bank and its goal is to practice DOM manipulation and user interaction. Bankist has an option to log in to the account and use different functionalities, almost like in an actual bank! By logging into your account you can transfer money to another account, take a loan or delete the account. Please check README for login info.',
  },
  {
    id: 'pig-game',
    img: PigGame,
    name: 'Pig Game',
    stack: ['< Vanilla JS />', '< CSS3 />', '< HTML5 />'],
    src: 'https://www.ahamam.com/pig-game/',
    source: 'https://github.com/AhmedHamamm/Pig-Game',
    description:
      'Pig Game is an exciting and strategic dice game that challenges your decision-making skills and luck. Built with HTML, CSS, and JavaScript, this web-based game offers a seamless experience across both desktop and mobile devices. Whether you are looking for a quick diversion or a competitive showdown with a friend, Pig Game delivers a blend of suspense, strategy, and fun.',
  },
  {
    id: 'countries',
    img: Countries,
    name: 'Countires',
    stack: ['< RESTful API />', '< CSS3 />', '< React.js />'],
    src: 'https://restful-countries.vercel.app/',
    description:
      'REST Countries is a project made with API which also has a color theme switcher feature. You can view the list of all countries and find a specific one via search or by filtering regions. By navigating to the individual country page, you are able to see more details about the country like native name and even border countries.',
  },
  {
    id: 'sushi-paradise',
    img: SushiParadise,
    name: 'Sushi Paradise',
    stack: ['< HTML5 />', '< CSS3 />', '< Vanilla JS />'],
    src: 'https://www.ahamam.com/sushi-paradise/',
    source: 'https://github.com/AhmedHamamm/Sushi-Paradise',
    description:
      'This project showcases a beautifully designed sushi website created using HTML, CSS, and JavaScript. The site is a portfolio piece demonstrating frontend web development skills and a passion for sushi.  ',
  },
  {
    id: 'guess-my-number',
    img: GuessMyNumber,
    name: 'Gues my number',
    stack: ['< HTML5 />', '< CSS3 />', '< Vanilla JS />'],
    src: 'https://www.ahamam.com/guess-my-number/',
    source: 'https://github.com/AhmedHamamm/guess-my-number',
    description:
      '"Guess My Number" is a simple number guessing game built using JavaScript, HTML, and CSS. The player tries to guess a randomly generated number within a specified range. With each guess, the game provides feedback to help the player hone in on the correct number.',
  },
];

export { projectsData };
